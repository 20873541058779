<template>
  <div class="main complete">
    <div class="main__block">
      <div class="title">預約成功</div>
      <div class="flex f-col jcs ais" v-if="Object.keys(resv).length > 0">
        <div class="desc w-100">
          您報到的時間：{{ calCheckinTime(resv.date, resv.time.checkin) }}
        </div>
        <!-- <div class="desc w-100">您治療的時間：{{ resv.date }}</div> -->
        <div class="desc w-100">
          <span v-if="dental_is_enable == '2'">
            <span v-if="resv.item === '除毛'">您預約的醫師：{{ resv.sfname }}</span>
            <span v-if="resv.item.indexOf('雷美') > -1">您預約的醫師：{{ resv.sfname }}</span>
            <span v-if="resv.item === '果酸'">您預約的美容師：{{ resv.sfname }}</span>
          </span>
          <span v-else-if="dental_is_enable == '3'">
            您預約的治療師：{{ resv.sfname }}
          </span>
          <span v-else>
            您預約的醫師：{{ resv.sfname }}
          </span>
        </div>
        <div class="desc w-100">
          您預約的項目：<span v-if="note">{{ note }}</span>
        </div>

        <br />
        <div class="btn w-100" @click="backToLogin">確認</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Complete',
  components: {},
  computed: {
    ...mapGetters('resvModule', ['resvByC', 'noteByC']),
    ...mapGetters('authModule', ['dentalIsEnableByC']),
    resv() {
      const clinicCode = this.$route.query.clinicCode;
      return this.resvByC(clinicCode);
    },
    dental_is_enable() {
      const clinicCode = this.$route.query.clinicCode;
      return this.dentalIsEnableByC(clinicCode);
    },
    note() {
      const clinicCode = this.$route.query.clinicCode;
      return this.noteByC(clinicCode);
    },
  },
  methods: {
    checkClinic() {
      if (this.$route.query.clinicCode) {
        localStorage.setItem('clinicCode', this.$route.query.clinicCode);
      } else {
        alert('診所代碼錯誤,請重新登入！');
        this.backToLogin();
      }
    },
    backToLogin() {
      const clinicCode =  this.$route.query.clinicCode;
      this.$router.push(`/login?clinicCode=${clinicCode}`);
    },
    calCheckinTime(ddate, checkin = 0) {
      let dd = ddate.split(' ')[0];
      dd = dd.split('-');
      let sch_time = ddate.split(' ')[1];

      const y = dd[0];
      const m = dd[1];
      const d = dd[2];

      const tt = sch_time.split(':');
      const h = tt[0];
      const mm = tt[1];
      let dt = new Date(y, m, d, h, mm, 0);
      console.log(checkin);
      dt.setMinutes(dt.getMinutes() - checkin);

      const hour = this.padLeft(dt.getHours(), 2, '0');
      const min = this.padLeft(dt.getMinutes(), 2, '0');
      const checkinTime = `${y}-${m}-${d} ${hour}:${min}`;
      return checkinTime;
    },
    padLeft(str, lenght, padstr = '0') {
      str = '' + str;
      return str.length >= lenght
        ? str
        : new Array(lenght - str.length + 1).join(padstr) + str;
    },
  },
  async created() {
    await this.checkClinic();
    window.scrollTo(0, 0);
    if (Object.keys(this.resv).length == 0) {
      // this.$router.go(-1);
    }
  },
};
</script>
