import axios from 'axios';
import https from 'https';
const httpsAgent = new https.Agent({
  rejectUnauthorized: false,
});
const instance = axios.create({
  baseURL: `${process.env.VUE_APP_APIURL}/api`,
  httpsAgent,
});

export default {
  namespaced: true,
  state: {
    online_resv_np: '0',
    captcha: {},
    message: '',
  },
  actions: {
    getSettings(context, status) {
      return new Promise((resolve, reject) => {
        const clinicCode = status;
        const api = `/settings`;
        context.commit('LOADING', true, { root: true });
        instance({
          method: 'post',
          url: api,
          headers: { clinic_code: clinicCode },
        })
          .then((res) => {
            let online_resv_np,
              dental_is_enable,
              online_prefix,
              bookings,
              resv_hours = 0;
            let info = {};
            if (res.data.msg == 'settings api not found') {
              online_resv_np = 0;
            } else {
              info = res.data.info;
              online_resv_np = res.data.data.settings.online_resv_np;
              dental_is_enable = res.data.data.settings.dental_is_enable;
              online_prefix =
                res.data.data.settings.online_prefix !== undefined
                  ? res.data.data.settings.online_prefix
                  : '';
              bookings = res.data.data.bookings;
              resv_hours = res.data.data.resv_hours;
            }
            context.commit('SET_CLINIC', { info, clinicCode });
            context.commit('ONLINE_RESV_NP', { online_resv_np, clinicCode});
            context.commit('DENTAL_IS_ENABLE', { dental_is_enable, clinicCode });
            context.commit('ONLINE_PREFIX', { online_prefix, clinicCode });
            context.commit('BOOKINGS', { bookings, clinicCode });
            context.commit('RESV_HOURS', { resv_hours, clinicCode });
            context.commit('LOADING', false, { root: true });
            resolve(res);
          })
          .catch((err) => {
            context.commit('LOADING', false, { root: true });
            reject(err);
          });
      });
    },
    getCaptcha(context) {
      return new Promise((resolve, reject) => {
        let clinicCode = '';
        if (localStorage.getItem('clinicCode')) {
          clinicCode = localStorage.getItem('clinicCode');
        }
        const api = `/captchas`;
        context.commit('LOADING', true, { root: true });
        instance({
          method: 'post',
          url: api,
          headers: { clinic_code: clinicCode },
        })
          .then((res) => {
            context.commit('CAPTCHA', res.data.data.captcha);
            context.commit('LOADING', false, { root: true });
            resolve(res);
          })
          .catch((err) => {
            context.commit('LOADING', false, { root: true });
            reject(err);
          });
      });
    },
    login(context, status) {
      return new Promise((resolve, reject) => {
        const { data, clinicCode, justNp } = status;
        // justNp -> 只允許新患者登入預約

        const api = `/customers/check`;
        context.commit('LOADING', true, { root: true });
        instance({
          method: 'post',
          url: api,
          data: data,
          headers: { clinic_code: clinicCode },
        })
          .then((res) => {
            console.warn(res);
            if (res.data.status == 'error') {
              reject(res.data.msg);
            } else {
              let {
                token,
                customer,
                rooms,
                registrations,
                newpatient,
              } = res.data.data;
              let nnp = {};
              if (justNp && customer) {
                const error = new Error('僅開放新顧客約診');
                reject(error);
              }
              if (!customer && !newpatient) {
                nnp = { birthday: '', name: '', mobile: data.get('mobile') };
              }
              console.log(nnp);
              customer = customer ? customer : {};
              newpatient = newpatient ? newpatient : {};

              context.commit('TOKEN', { data: token, clinicCode, }, { root: true });
              context.commit('CUSTOMER', { customer, clinicCode, });
              context.commit('NEWPATIENT', { newpatient, clinicCode, });
              context.commit('NNP', { nnp, clinicCode, });
              context.commit('ROOMS', { rooms, clinicCode, });
              context.dispatch('resvModule/setRegistrations', { registrations, clinicCode }, {
                root: true,
              });
              resolve(res);
            }
            context.commit('LOADING', false, { root: true });
          })
          .catch((err) => {
            console.log(err)
            if (err.response.data.status == 'failed') {
              err = new Error(err.response.data.message);
            }
            context.commit('LOADING', false, { root: true });
            reject(err);
          });
      });
    },
    setNnp(context, status) {
      context.commit('NNP', status);
    },
  },
  mutations: {
    SET_CLINIC(state, status) {
      const { info, clinicCode } = status;
      // localStorage.setItem(`clinic_${clinicCode}`, '');
      localStorage.setItem(`clinic_${clinicCode}`, JSON.stringify(info));
    },
    ONLINE_RESV_NP(state, status) {
      const { online_resv_np, clinicCode } = status;
      // localStorage.setItem(`online_resv_np_${clinicCode}`, '');
      localStorage.setItem(`online_resv_np_${clinicCode}`, JSON.stringify(online_resv_np));
    },
    ONLINE_PREFIX(state, status) {
      const { online_prefix, clinicCode } = status;
      // localStorage.setItem(`online_prefix_${clinicCode}`, '');
      localStorage.setItem(`online_prefix_${clinicCode}`, JSON.stringify(online_prefix));
    },
    DENTAL_IS_ENABLE(state, status) {
      const { dental_is_enable, clinicCode } = status;
      // localStorage.setItem(`dental_is_enable_${clinicCode}`, '');
      localStorage.setItem(`dental_is_enable_${clinicCode}`, JSON.stringify(dental_is_enable));
    },
    BOOKINGS(state, status) {
      const { bookings, clinicCode } = status;
      // localStorage.setItem(`bookings_${clinicCode}`, '');
      localStorage.setItem(`bookings_${clinicCode}`, JSON.stringify(bookings));
    },
    RESV_HOURS(state, status) {
      const { resv_hours, clinicCode } = status;
      // localStorage.setItem(`resv_hours_${clinicCode}`, '');
      localStorage.setItem(`resv_hours_${clinicCode}`, JSON.stringify(resv_hours));
    },
    CAPTCHA(state, status) {
      state.captcha = status;
    },
    MESSAGE(state, status) {
      state.message = status;
    },
    CUSTOMER(state, status) {
      const { customer, clinicCode } = status;
      // localStorage.setItem(`customer_${clinicCode}`, '');
      localStorage.setItem(`customer_${clinicCode}`, JSON.stringify(customer));
    },
    NEWPATIENT(state, status) {
      const { newpatient, clinicCode } = status;
      // localStorage.setItem(`newpatient_${clinicCode}`, '');
      localStorage.setItem(`newpatient_${clinicCode}`, JSON.stringify(newpatient));
    },
    NNP(state, status) {
      const { nnp, clinicCode } = status;
      const { birthday, mobile, name } = nnp;
      // localStorage.setItem(`nnp_${clinicCode}`, '');
      console.log({ birthday, mobile, name })
      localStorage.setItem(`nnp_${clinicCode}`, JSON.stringify({ birthday, mobile, name }));
    },
    ROOMS(state, status) {
      const { rooms, clinicCode } = status;
      // localStorage.setItem(`rooms_${clinicCode}`, '');
      localStorage.setItem(`rooms_${clinicCode}`, JSON.stringify(rooms));
    },
  },
  getters: {
    clinicByC: () => (clinicCode) => {
      const clinic = localStorage.getItem(`clinic_${clinicCode}`);
      console.log('clinic', clinic);
      if (clinic !== null) {
        return JSON.parse(clinic);
      } else {
        return {};
      }
    },
    onlineResvNp: () => (clinicCode) => {
      const online_resv_np = localStorage.getItem(`online_resv_np_${clinicCode}`);
      if (online_resv_np) {
        return JSON.parse(online_resv_np);
      } else {
        return {};
      }
    },
    dentalIsEnableByC: () => (clinicCode) => {
      const dental_is_enable = localStorage.getItem(`dental_is_enable_${clinicCode}`);
      if (dental_is_enable) {
        return JSON.parse(dental_is_enable);
      } else {
        return {};
      }
    },
    bookingsByC: () => (clinicCode) => {
      const bookings = localStorage.getItem(`bookings_${clinicCode}`);
      if (bookings) {
        return JSON.parse(bookings);
      } else {
        return {};
      }
    },
    resvHoursByC: () => (clinicCode) => {
      const resv_hours = localStorage.getItem(`resv_hours_${clinicCode}`);
      if (resv_hours) {
        return JSON.parse(resv_hours);
      } else {
        return {};
      }
    },
    captcha(state) {
      return state.captcha;
    },
    customerByC: () => (clinicCode) => {
      const customer = localStorage.getItem(`customer_${clinicCode}`);
      if (customer) {
        return JSON.parse(customer);
      } else {
        return {};
      }
    },
    newpatientByC: () => (clinicCode) => {
      const newpatient = localStorage.getItem(`newpatient_${clinicCode}`);
      if (newpatient) {
        return JSON.parse(newpatient);
      } else {
        return {};
      }
    },
    nnpByC: () => (clinicCode) => {
      const nnp = localStorage.getItem(`nnp_${clinicCode}`);
      if (nnp) {
        return JSON.parse(nnp);
      } else {
        return {};
      }
    },
    roomsByC: () => (clinicCode) => {
      const rooms = localStorage.getItem(`rooms_${clinicCode}`);
      return JSON.parse(rooms);
    },
    message(state) {
      return state.message;
    },
  },
};
