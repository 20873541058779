<template>
  <div id="app">
    <transition name="fade">
      <router-view/>
    </transition>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  components: {
  },
  watch: {
    clinic: {
      handler() {
        if (this.clinic.name) {
          document.title = `${this.clinic.name}線上快速預約`;
        } else {
          document.title = `線上快速預約`;
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters('authModule', ['customerByC', 'clinicByC']),
    clinic() {
      const clinicCode = this.$route.query.clinicCode;
      return this.clinicByC(clinicCode);
    },
  }
}
</script>
<style>
</style>

