export function verifyId(id) {
  // 身分證格式驗證
  id = id.trim();

  let verification = id.match('^[A-Z][A-D1289]\\d{8}$');
  if (!verification) {
    return false;
  }

  let conver = 'ABCDEFGHJKLMNPQRSTUVXYWZIO';
  let weights = [1, 9, 8, 7, 6, 5, 4, 3, 2, 1, 1];

  // 先判斷新式或舊式外國人規則
  const sec = ['A', 'B', 'C', 'D'];
  if (sec.indexOf(id[1]) > -1) {
    // 舊式，第二碼為Ａ,B,C,D
    id =
      String(conver.indexOf(id[0]) + 10) +
      String((conver.indexOf(id[1]) + 10) % 10) +
      id.slice(2);
  } else {
    // 新式，第二碼為數字(8: 男, 9: 女)
    id = String(conver.indexOf(id[0]) + 10) + id.slice(1);
  }

  console.log('id', id);

  let checkSum = 0;
  for (let i = 0; i < id.length; i++) {
    let c = parseInt(id[i]);
    let w = weights[i];
    checkSum += c * w;
  }

  return checkSum % 10 == 0;
}
export function verifyPhone(phone) {
  // 手機號碼格式驗證
  phone = phone.trim();
  let verification = phone.match('^09\\d{8}$');
  if (!verification) {
    return false;
  }
  return true;
}
