<template>
  <div class="main jcc ais">
    <div class="main__block">
      <div class="title">選擇果酸項目</div>
      <!-- <span class="desc">預估時間：<span>{{ needTime.schlen }}分鐘</span></span> -->
      <div class="flex f-col jcs ais w-100">
        <div class="part__body">
          <div class="choose__item square">
            <input v-model="tempAcid" value="醫療級果酸+清痘(25分鐘) 1200" type="radio" />
            <div class="checkTag">
              <span>醫療級果酸+清痘(25分鐘)<br>1200</span>
            </div>
          </div>
          <div class="choose__item square">
            <input v-model="tempAcid" value="醫療級清痘(45分鐘) 1500" type="radio" />
            <div class="checkTag">
              <span>醫療級清痘(45分鐘)<br>1500</span>
            </div>
          </div>
          <div class="choose__item square">
            <input v-model="tempAcid" value="醫療級果酸+清痘(45分鐘) 1800" type="radio" />
            <div class="checkTag">
              <span>醫療級果酸+清痘(45分鐘)<br>1800</span>
            </div>
          </div>
          <div class="choose__item square">
            <input v-model="tempAcid" value="醫療級果酸+清痘+導入(45分鐘) 2200" type="radio" />
            <div class="checkTag">
              <span>醫療級果酸+清痘+導入(45分鐘)<br>2200</span>
            </div>
          </div>
          <div class="choose__item square">
            <input v-model="tempAcid" value="身體果酸(30分鐘) 2500起" type="radio" />
            <div class="checkTag">
              <span>身體果酸(30分鐘)<br>2500起</span>
            </div>
          </div>
        </div>
        
        <div class="flex f-row jce w-100">
          <div @click="chooseTime" class="btn next">下一步</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ChooseAcid',
  components: {},
  data() {
    return {
      tempAcid: ''
    };
  },
  methods: {
    checkClinic() {
      const clinicCode = this.$route.query.clinicCode;
      if (clinicCode) {
        localStorage.setItem('clinicCode', clinicCode);
      } else {
        alert('診所代碼錯誤,請重新登入！');
        this.backToLogin();
      }
    },
    backToLogin() {
      const clinicCode = this.$route.query.clinicCode;
      this.$router.push(`/login?clinicCode=${clinicCode}`);
    },
    async chooseTime() {
      const clinicCode = localStorage.getItem('clinicCode');
      const { schlen, checkin } = this.needTime;
      console.log(schlen, checkin);
      if (schlen > 0 && checkin > 0) {
        try {
          await this.$store.dispatch('resvModule/setTime', { data: this.needTime, clinicCode, });
          await this.$store.dispatch('resvModule/setNote', { data: this.note, clinicCode, });
          this.$router.push(`/choose-time?clinicCode=${this.$route.query.clinicCode}`);
        } catch (e) {
          const tokenError = ['無效的token', 'token錯誤', '該token已失效'];
          if (tokenError.indexOf(e) > -1) {
            alert('請重新登入');
            this.$router.push(`/login?clinicCode=${clinicCode}`);
          } else if (e == 'his api not found') {
            alert('請重新登入');
            this.$router.push(`/login?clinicCode=${clinicCode}`);
          } else {
            alert(e);
          }
          console.log(e);
        }
      } else if (schlen == null && checkin == null) {
        alert('尚未選擇項目！');
      }
    },
  },
  computed: {
    ...mapGetters('authModule', ['customerByC']),
    ...mapGetters('resvModule', ['roomByC']),
    customer() {
      const clinicCode = this.$route.query.clinicCode;
      return this.customerByC(clinicCode);
    },
    room() {
      const clinicCode = this.$route.query.clinicCode;
      return this.roomByC(clinicCode);
    },
    needTime() {
      let schlen, checkin = 0; 
      switch(this.tempAcid) {
        case '醫療級果酸+清痘(25分鐘) 1200':
          schlen = 30;
          checkin = 10;
          break;
        case '醫療級清痘(45分鐘) 1500':
          schlen = 60;
          checkin = 10;
          break;
        case '醫療級果酸+清痘(45分鐘) 1800':
          schlen = 60;
          checkin = 10;
          break;
        case '醫療級果酸+清痘+導入(45分鐘) 2200':
          schlen = 60;
          checkin = 10;
          break;
        case '身體果酸(30分鐘) 2500起':
          schlen = 30;
          checkin = 10;
          break;
      }
      return { schlen, checkin, };
    },
    note() {
      return `${this.room.name}(${this.tempAcid})`;
    }
  },
  async created() {
    await this.checkClinic();
    window.scrollTo(0, 0);
  },
};
</script>
