import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/scss/index.scss'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

const clinicCode = localStorage.getItem('clinicCode');

router.beforeEach((to,from,next) => {
  if(to.meta.auth){
    if(localStorage.getItem(`resv_token_${clinicCode}`) == null){
      alert('請重新登入!');
      next(`/login?clinicCode=${clinicCode}`);
    } else{
      next();
    }
  } else{
    next();
  }
})