<template>
  <div class="main jcc ais">
    <div class="main__block">
      <div class="title">選擇除毛項目</div>
      <!-- <span class="desc">預估時間：<span>{{ needTime.schlen }}分鐘</span></span> -->
      <div class="flex f-col jcs ais w-100">
        <!-- 臉部 -->
        <div class="part__cate">
          <div class="part__cate__title" @click="isCollapse1 = !isCollapse1">
            臉部
            <div class="arrow" :class="isCollapse1 ? 'down' : 'up'"></div>
          </div>
          <transition name="slide-fade">
            <div class="part__body" v-if="isCollapse1">
              <div class="choose__item">
                <input v-model="parts['S']" value="人中" type="checkbox" />
                <div class="checkTag">
                  <span>人中</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['S']" value="髮際" type="checkbox" />
                <div class="checkTag">
                  <span>髮際</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['M']" value="臉" type="checkbox" />
                <div class="checkTag">
                  <span>臉</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['M']" value="鬍鬚" type="checkbox" />
                <div class="checkTag">
                  <span>鬍鬚</span>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <!-- 私密處 只有女性可除私密處 -->
        <div class="part__cate" v-if="customer.cussex === 0">
          <div class="part__cate__title" @click="isCollapse2 = !isCollapse2">
            私密處
            <div class="arrow" :class="isCollapse2 ? 'down' : 'up'"></div>
          </div>
          <transition name="slide-fade">
            <div class="part__body" v-if="isCollapse2">
              <div class="choose__item">
                <input v-model="parts['PH']" value="比基尼" type="checkbox" />
                <div class="checkTag">
                  <span>比基尼</span>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <!-- 身體 -->
        <div class="part__cate">
          <div class="part__cate__title" @click="isCollapse3 = !isCollapse3">
            身體
            <div class="arrow" :class="isCollapse3 ? 'down' : 'up'"></div>
          </div>
          <transition name="slide-fade">
            <div class="part__body" v-if="isCollapse3">
              <div class="choose__item">
                <input v-model="parts['S']" value="腋下" type="checkbox" />
                <div class="checkTag">
                  <span>腋下</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['S']" value="手指" type="checkbox" />
                <div class="checkTag">
                  <span>手指</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['S']" value="腳趾" type="checkbox" />
                <div class="checkTag">
                  <span>腳趾</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['S']" value="乳暈" type="checkbox" />
                <div class="checkTag">
                  <span>乳暈</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['S']" value="膝蓋" type="checkbox" />
                <div class="checkTag">
                  <span>膝蓋</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['S']" value="上/下腹線" type="checkbox" />
                <div class="checkTag">
                  <span>上/下腹線</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['M']" value="後頸" type="checkbox" />
                <div class="checkTag">
                  <span>後頸</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['L']" value="上手臂" type="checkbox" />
                <div class="checkTag">
                  <span>上手臂</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['L']" value="下手臂" type="checkbox" />
                <div class="checkTag">
                  <span>下手臂</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['L']" value="上背" type="checkbox" />
                <div class="checkTag">
                  <span>上背</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['L']" value="下背" type="checkbox" />
                <div class="checkTag">
                  <span>下背</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['L']" value="上胸" type="checkbox" />
                <div class="checkTag">
                  <span>上胸</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['L']" value="下腹" type="checkbox" />
                <div class="checkTag">
                  <span>下腹</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['XL']" value="小腿" type="checkbox" />
                <div class="checkTag">
                  <span>小腿</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['XL']" value="全手" type="checkbox" />
                <div class="checkTag">
                  <span>全手</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['XL']" value="全胸" type="checkbox" />
                <div class="checkTag">
                  <span>全胸</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['XL']" value="全背" type="checkbox" />
                <div class="checkTag">
                  <span>全背</span>
                </div>
              </div>
              <div class="choose__item">
                <input v-model="parts['XXL']" value="大腿" type="checkbox" />
                <div class="checkTag">
                  <span>大腿</span>
                </div>
              </div>
            </div>
          </transition>
        </div>
        <div class="flex f-row jce w-100">
          <div @click="chooseTime" class="btn next">下一步</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ChoosePart',
  components: {},
  data() {
    return {
      isCollapse1: true,
      isCollapse2: true,
      isCollapse3: true,
      isCollapse4: true,
      parts: {
        S: [],
        M: [],
        L: [],
        XL: [],
        XXL: [],
        PH: [],
      },
    };
  },
  methods: {
    checkClinic() {
      if (this.$route.query.clinicCode) {
        localStorage.setItem('clinicCode', this.$route.query.clinicCode);
      } else {
        alert('診所代碼錯誤,請重新登入！');
        this.backToLogin();
      }
    },
    backToLogin() {
      const clinicCode = this.$route.query.clinicCode;
      this.$router.push(`/login?clinicCode=${clinicCode}`);
    },
    async chooseTime() {
      const clinicCode = localStorage.getItem('clinicCode');
      const { schlen, checkin } = this.needTime;
      console.log(schlen, checkin);
      if (schlen > 0 && checkin > 0) {
        try {
          await this.$store.dispatch('resvModule/setTime', { data: this.needTime, clinicCode, });
          await this.$store.dispatch('resvModule/setNote', { data: this.note, clinicCode, });
          await this.$store.dispatch('resvModule/setParts', { data: this.partList.toString(), clinicCode, });
          this.$router.push(`/choose-time?clinicCode=${clinicCode}`);
        } catch (e) {
          const tokenError = ['無效的token', 'token錯誤', '該token已失效'];
          if (tokenError.indexOf(e) > -1) {
            alert('請重新登入');
            this.$router.push(`/login?clinicCode=${clinicCode}`);
          } else if (e == 'his api not found') {
            alert('請重新登入');
            this.$router.push(`/login?clinicCode=${clinicCode}`);
          } else {
            alert(e);
          }
          console.log(e);
        }
      } else if (schlen == null && checkin == null) {
        alert('尚未選擇部位！');
      } else {
        alert('選取的項目較為複雜，請電洽診所預約，謝謝');
      }
    },
  },
  computed: {
    ...mapGetters('authModule', ['customerByC']),
    ...mapGetters('resvModule', ['roomByC']),
    customer() {
      const clinicCode = this.$route.query.clinicCode;
      return this.customerByC(clinicCode);
    },
    room() {
      const clinicCode = this.$route.query.clinicCode;
      return this.roomByC(clinicCode);
    },
    needTime() {
      const vm = this;
      let time = 0;
      let pre = 0;
      const S = vm.parts['S'].length;
      const M = vm.parts['M'].length;
      const L = vm.parts['L'].length;
      const XL = vm.parts['XL'].length;
      const XXL = vm.parts['XXL'].length;
      const PH = vm.parts['PH'].length;
      if (S == 0 && M == 1 && L == 1 && XL == 0 && XXL == 0 && PH == 0) {
        // 1L 1M
        time = 15;
        pre = 30;
      } else if (S >= 3 && M == 0 && L == 1 && XL == 0 && XXL == 0 && PH == 0) {
        // 1L 3S^
        time = 15;
        pre = 15;
      } else if (
        S > 0 &&
        S < 3 &&
        M == 0 &&
        L == 1 &&
        XL == 0 &&
        XXL == 0 &&
        PH == 0
      ) {
        // 1L 3Sˇ
        time = 10;
        pre = 15;
      } else if (S >= 3 && M == 0 && L == 0 && XL == 1 && XXL == 0 && PH == 0) {
        // 1XL 3S^
        time = 20;
        pre = 15;
      } else if (
        S > 0 &&
        S < 3 &&
        M == 0 &&
        L == 0 &&
        XL == 1 &&
        XXL == 0 &&
        PH == 0
      ) {
        // 1XL 3Sˇ
        time = 15;
        pre = 15;
      } else if (
        S == 0 &&
        M >= 1 &&
        M <= 2 &&
        L == 0 &&
        XL == 1 &&
        XXL == 0 &&
        PH == 0
      ) {
        // 1XL 1~2M
        time = 20;
        pre = 30;
      } else if (
        S == 0 &&
        M >= 1 &&
        M <= 2 &&
        L == 0 &&
        XL == 0 &&
        XXL == 1 &&
        PH == 0
      ) {
        // 1XXL 1~2M
        time = 25;
        pre = 30;
      } else if (S >= 3 && M == 0 && L == 0 && XL == 0 && XXL == 1 && PH == 0) {
        // 1XXL 3S^
        time = 25;
        pre = 30;
      } else if (
        S > 0 &&
        S < 3 &&
        M == 0 &&
        L == 0 &&
        XL == 0 &&
        XXL == 1 &&
        PH == 0
      ) {
        // 1XXL 3Sˇ
        time = 20;
        pre = 15;
      } else if (S == 6 && M == 0 && L == 0 && XL == 0 && XXL == 0 && PH == 0) {
        // 6S
        time = 10;
        pre = 15;
      } else if (S == 0 && M == 1 && L == 0 && XL == 0 && XXL == 0 && PH == 1) {
        // 1PH 1M
        time = 15;
        pre = 40;
      } else if (S == 0 && M == 0 && L == 1 && XL == 0 && XXL == 0 && PH == 1) {
        // 1PH 1L
        time = 20;
        pre = 40;
      } else if (S == 0 && M == 0 && L == 0 && XL == 1 && XXL == 0 && PH == 1) {
        // 1PH 1XL
        time = 25;
        pre = 40;
      } else if (S == 0 && M == 0 && L == 0 && XL == 0 && XXL == 1 && PH == 1) {
        // 1PH 1XXL
        time = 30;
        pre = 40;
      } else if (S == 0 && M == 2 && L == 0 && XL == 0 && XXL == 0 && PH == 0) {
        // 2M
        time = 5;
        pre = 30;
      } else if (S == 0 && M == 1 && L == 0 && XL == 1 && XXL == 0 && PH == 0) {
        // 1M 1XL
        time = 15;
        pre = 30;
      } else if (S == 1 && M == 1 && L == 0 && XL == 0 && XXL == 0 && PH == 0) {
        // 1M 1S
        time = 5;
        pre = 15;
      } else if (S == 1 && M == 0 && L == 1 && XL == 0 && XXL == 0 && PH == 0) {
        // 1L 1S
        time = 10;
        pre = 15;
      } else if (S == 1 && M == 0 && L == 0 && XL == 1 && XXL == 0 && PH == 0) {
        // 1XL 1S
        time = 15;
        pre = 15;
      } else if (S == 1 && M == 0 && L == 0 && XL == 0 && XXL == 1 && PH == 0) {
        // 1XXL 1S
        time = 20;
        pre = 30;
      } else if (S == 1 && M == 0 && L == 0 && XL == 0 && XXL == 0 && PH == 1) {
        // 1PH 1S
        time = 10;
        pre = 40;
      } else if (S == 1 && M == 0 && L == 0 && XL == 0 && XXL == 0 && PH == 0) {
        // 1S
        time = 5;
        pre = 15;
      } else if (S == 2 && M == 0 && L == 0 && XL == 0 && XXL == 0 && PH == 0) {
        // 2S
        time = 5;
        pre = 15;
      } else if (S == 3 && M == 0 && L == 0 && XL == 0 && XXL == 0 && PH == 0) {
        // 3S
        time = 5;
        pre = 15;
      } else if (S == 4 && M == 0 && L == 0 && XL == 0 && XXL == 0 && PH == 0) {
        // 4S
        time = 5;
        pre = 15;
      } else if (S == 5 && M == 0 && L == 0 && XL == 0 && XXL == 0 && PH == 0) {
        // 5S
        time = 5;
        pre = 15;
      } else if (S == 0 && M == 1 && L == 0 && XL == 0 && XXL == 0 && PH == 0) {
        // 1M
        time = 5;
        pre = 15;
      } else if (S == 0 && M == 0 && L == 1 && XL == 0 && XXL == 0 && PH == 0) {
        // 1L
        time = 10;
        pre = 15;
      } else if (S == 0 && M == 0 && L == 0 && XL == 1 && XXL == 0 && PH == 0) {
        // 1XL
        time = 15;
        pre = 15;
      } else if (S == 0 && M == 0 && L == 0 && XL == 0 && XXL == 1 && PH == 0) {
        // 1XXL
        time = 20;
        pre = 15;
      } else if (S == 0 && M == 0 && L == 0 && XL == 0 && XXL == 0 && PH == 1) {
        // 1PH
        time = 10;
        pre = 40;
      } else if (S == 0 && M == 0 && L == 0 && XL == 0 && XXL == 0 && PH == 0) {
        // 沒選擇部位
        time = null;
        pre = null;
      } else {
        // 選取的項目較為複雜，請電洽診所預約，謝謝
        time = 0;
        pre = 0;
      }
      return { schlen: time, checkin: pre };
    },
    partList() {
      const vm = this;
      let note = [];
      const S = vm.parts['S'];
      const M = vm.parts['M'];
      const L = vm.parts['L'];
      const XL = vm.parts['XL'];
      const XXL = vm.parts['XXL'];
      const PH = vm.parts['PH'];
      if (S.length > 0) {
        note.push(S.toString());
      }
      if (M.length > 0) {
        note.push(M.toString());
      }
      if (L.length > 0) {
        note.push(L.toString());
      }
      if (XL.length > 0) {
        note.push(XL.toString());
      }
      if (XXL.length > 0) {
        note.push(XXL.toString());
      }
      if (PH.length > 0) {
        note.push(PH.toString());
      }
      return note;
    },
    note() {
      return `${this.room.name}(${this.partList.toString()})`;
    }
  },
  async created() {
    await this.checkClinic();
    window.scrollTo(0, 0);
  },
};
</script>
