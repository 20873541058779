<template>
  <div class="main jcc ais">
    <div class="main__block">
      <div class="title">選擇問題</div>
      <div class="flex f-col jcs ais w-100">
        <div class="part__body">
          <div v-for="prob in problemList" :key="prob" class="choose__item square">
            <input v-model="tempMedProblem" :value="prob" type="checkbox" />
            <div class="checkTag">
              <span>{{ prob }}</span>
            </div>
          </div>
        </div>
        
        <div class="flex f-row jce w-100">
          <div @click="chooseTime" class="btn next">下一步</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ChooseMedProblem',
  components: {},
  data() {
    return {
      tempMedProblem: []
    };
  },
  methods: {
    checkClinic() {
      const clinicCode = this.$route.query.clinicCode;
      if (clinicCode) {
        localStorage.setItem('clinicCode', clinicCode);
      } else {
        alert('診所代碼錯誤,請重新登入！');
        this.backToLogin();
      }
    },
    backToLogin() {
      const clinicCode = this.$route.query.clinicCode;
      this.$router.push(`/login?clinicCode=${clinicCode}`);
    },
    async chooseTime() {
      const clinicCode = localStorage.getItem('clinicCode');
      try {
        await this.$store.dispatch('resvModule/setTime', { data: this.needTime, clinicCode, });
        if (this.tempMedProblem.length > 0) {
          await this.$store.dispatch('resvModule/setMedProblem', { data: this.tempMedProblem.join(','), clinicCode, });
        }
        this.$router.push(`/choose-time?clinicCode=${this.$route.query.clinicCode}`);
      } catch (e) {
        const tokenError = ['無效的token', 'token錯誤', '該token已失效'];
        if (tokenError.indexOf(e) > -1) {
          alert('請重新登入');
          this.$router.push(`/login?clinicCode=${clinicCode}`);
        } else if (e == 'his api not found') {
          alert('請重新登入');
          this.$router.push(`/login?clinicCode=${clinicCode}`);
        } else {
          alert(e);
        }
        console.log(e);
      }
    },
  },
  computed: {
    ...mapGetters('authModule', ['customerByC', 'clinicByC']),
    ...mapGetters('resvModule', ['roomByC']),
    customer() {
      const clinicCode = this.$route.query.clinicCode;
      return this.customerByC(clinicCode);
    },
    room() {
      const clinicCode = this.$route.query.clinicCode;
      return this.roomByC(clinicCode);
    },
    needTime() {
      const schlen = 10;
      const checkin = 0;
      return { schlen, checkin, };
    },
    clinic() {
      const clinicCode = this.$route.query.clinicCode;
      return this.clinicByC(clinicCode);
    },
    problemList() {
      let problemList = [];
      if (this.clinic.name == '膚適美') {
        problemList = ['斑點色素問題','刺青', '胎記', '上下眼線', '紋繡眉', '凹洞痘疤', '毛孔問題', '紅色痘疤', '血管問題', '痣', '贅生物'];
      } else if (this.clinic.name.indexOf('木木日安') > -1) {
        problemList = ['斑點色素問題','刺青', '胎記', '上下眼線', '紋繡眉', '凹洞痘疤', '毛孔問題', '紅色痘疤', '血管問題', '痣', '贅生物', '黑色痘疤', '色素疤痕', '凹凸疤痕', '動態表情紋', '瘦小臉'];
      } else {
        problemList = ['斑點色素問題','刺青', '胎記', '上下眼線', '紋繡眉', '凹洞痘疤', '毛孔問題', '紅色痘疤', '血管問題', '痣', '贅生物', '黑色痘疤', '色素疤痕', '凹凸疤痕', '動態表情紋', '瘦小臉'];
      }
      return problemList;
    }
  },
  async created() {
    await this.checkClinic();
    window.scrollTo(0, 0);
  },
};
</script>
