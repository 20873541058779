import Vue from 'vue';
import Vuex from 'vuex';
import authModule from './auth';
import resvModule from './resv';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    token: '',
    function_list: '',
    // user: JSON.parse(localStorage.getItem('resv_user')),
    message: '',
  },
  actions: {
    setLoading(context, status) {
      context.commit('LOADING', status, { root: true });
    },
  },
  mutations: {
    LOADING(state, status) {
      state.isLoading = status;
    },
    TOKEN(state, status) {
      const { data, clinicCode } = status;
      localStorage.setItem(`resv_token_${clinicCode}`, data);
      state.token = data;
    },
    // USER(state, status) {
    //   state.user = status;
    // },
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    tokenByC: () => (clinicCode) => {
      const token = localStorage.getItem(`resv_token_${clinicCode}`);
      if (token) {
        return token;
      } else {
        return '';
      }
    },
    // user(state) {
    //   return state.user;
    // },
  },
  modules: {
    authModule,
    resvModule,
  },
});
