<template>
  <div class="lds-mask">
    <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    msg: String
  }
}
</script>
